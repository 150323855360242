import {API_URL, API_V2_URL} from '../config/Constants';

const defaultCatchCallback = err => {
    console.error(err)
}

const generateUrlApiV2 = relativeUrl => {
    return `${API_V2_URL}${relativeUrl}`;
}

const generateUrl = relativeUrl => {
    return `${API_URL}${relativeUrl}`;
}

const buildHeaders = () => {
    const headers = new Headers({
        'Content-Type': 'application/json'
    });
    return headers;
}

const buildHeadersAuthenticated = (token, v2) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': (v2 ? 'bearer ' : '') + token
    });
    return headers;
}

const generateRequest = (url, type, headers, payload) => {

    if (type === 'GET') {
        return new Request(url, {
            method: type,
            mode: 'cors'
        });
    } else {
        return new Request(url, {
            method: type,
            mode: 'cors',
            body: JSON.stringify(payload),
            headers
        });
    }
}



export {
    defaultCatchCallback,
    generateUrl,
    generateUrlApiV2,
    buildHeaders,
    buildHeadersAuthenticated,
    generateRequest,
    
};
