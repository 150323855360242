const initialState = {
  flakeId: undefined,
  idVote: undefined
};

export default (state = initialState, action) => {

  switch (action.type) {
    case 'TRACK_SESSION_START':
      return {
        ...state,
        flakeId: undefined
      }
    case 'TRACK_SESSION_FINISH':
      return {
        ...state,
        flakeId: action.payload.data.result.flakeId
      }
    case 'TRACK_SESSION_ERROR':
      return {
        ...state,
        flakeId: undefined
      }
    case 'TRACK_VOTE_START':
      return {
        ...state,
        idVote: undefined
      }
    case 'TRACK_VOTE_FINISH':
      console.log('0987654',action)
      return {
        ...state,
        idVote: action.payload.data && action.payload.data.result && action.payload.data.result.idVote
      }
    case 'TRACK_VOTE_ERROR':
      return {
        ...state,
        idVote: undefined
      }

    default:
      return state
  }
}
