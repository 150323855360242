import React, {Component} from 'react';

import './Footer.css';
import connect from "react-redux/es/connect/connect";
import { Trans,withTranslation} from 'react-i18next';
import { showEmbedFooter } from '../../util/helpers/Helpers';

class Footer extends Component {

    constructor(props) {

        super(props);
        this.state = {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const hasEmbedFooter = showEmbedFooter();
        if(hasEmbedFooter){
            window.parent.postMessage({ type: "CAMPAIGNWARE-FOOTER",
            showMadeWithBv:this.props.config.showMadeWithBv ,
            privacypolicy:this.props.config.privacypolicy, 
            privacypolicyTxt:this.props.t('privacy_policy'),
            tncs:this.props.config.tncs,
            tncsTxt: this.props.t('term_condition'),
            url:'https://www.campaignware.com/lp/try-campaignware?utm_source=footer+button&utm_medium=campaigns&utm_campaign=footer+button+landing+page'},
            "*");
        }
}

    render() {
       const {config} = this.props;
       const hasEmbedFooter = showEmbedFooter();
        return (this.props && this.props.config && !hasEmbedFooter) ? (
            <div className='footer'>
                   {config.tncs || config.privacypolicy || config.showMadeWithBv ? <div className={'fixed-container'}>
                {config.tncs || config.privacypolicy || config.showMadeWithBv ?
                    <div className={'floatingFooter ' + (!config.tncs && !config.privacypolicy ? 'floatingFooterHide' : '')}>
                        {config.tncs ?
                            <a href={config.tncs}
                               target="_blank"><Trans i18nKey="term_condition" /></a>
                            : null}
                        {config.tncs ? ' - ' : null}
                        {config.privacypolicy ?
                            <a href={config.privacypolicy}
                               target="_blank"><Trans i18nKey="privacy_policy" /></a>
                            : null}
                    </div> : null}
                {config.showMadeWithBv ?
                    <div className='made-with-bv'>
                    <div className='made-with-bv-desktop'>
                        <a href="https://www.campaignware.com/lp/try-campaignware?utm_source=footer+button&utm_medium=campaigns&utm_campaign=footer+button+landing+page" target="_blank" className="btn btn-sm p-l-20 p-r-20"><img className="footer-logo" src="https://benchvote-media-assets.s3-ap-southeast-2.amazonaws.com/common-images/campaignware_footer_logo.png"/></a>
                    {/* <a href="https://www.campaignware.com/lp/try-campaignware?utm_source=footer+button&utm_medium=campaigns&utm_campaign=footer+button+landing+page" target="_blank" className="btn btn-sm p-l-20 p-r-20"><Trans i18nKey="try_benchvote" /></a> */}
                        {/* <a href="https://www.campaignware.com/?utm_source=activations&utm_medium=activationfooter&utm_campaign=clickthroughs"
                            target="_blank">
                                <img src={'https://benchvote-media-assets.s3-ap-southeast-2.amazonaws.com/common-images/bv_logo_light.png'}
                                    alt="Made with BV" className='made-with-bv-image'/></a> */}
                    </div>
                    <div className='made-with-bv-mobile'>
                        <div class="build-with-bv"> 
                            <a href="https://www.campaignware.com/lp/try-campaignware?utm_source=footer+button&utm_medium=campaigns&utm_campaign=footer+button+landing+page" target="_blank"><img className="footer-logo" src="https://benchvote-media-assets.s3-ap-southeast-2.amazonaws.com/common-images/campaignware_footer_logo.png"/></a>
                            {/* <a href="https://www.campaignware.com/lp/try-campaignware?utm_source=footer+button&utm_medium=campaigns&utm_campaign=footer+button+landing+page" target="_blank"><Trans i18nKey="try_benchvote" /></a> */}
                        </div>
                        {/* <a href="https://www.campaignware.com/?utm_source=activations&utm_medium=activationfooter&utm_campaign=clickthroughs"
                            target="_blank">
                                <img src={'https://benchvote-media-assets.s3-ap-southeast-2.amazonaws.com/common-images/bv_logo_dark.png'}
                                    alt="Made with BV" className='made-with-bv-image'/></a> */}
                    </div>
                    </div> : null}
                    </div>:null}
            </div>
        ) : null;
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        config: state.default.data.config,
        locale: state.default.locale,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Footer));
