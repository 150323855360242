
const initialState = {
    config: {},
    offer:{},
    idOptin:undefined,
    idVote:undefined
};

export default (state=initialState, action) => {
   
    switch (action.type) {
        
        case 'GET_CONFIG_START': {
            return {
                ...state,
                config: {},
            }
        }
        case 'GET_CONFIG_FINISH': {
            return {
                ...state,
                config:action.payload.data
            }
        }

        case 'SUBMIT_START': {
            return {
                ...state,
                idOptin:undefined
            }
        }
        
        case 'SUBMIT_FINISH': {
            return {
                ...state,
                idOptin:action.payload.data.idOptin
            }
        }
        case 'OFFER_START': {
            return {
                ...state,
            }
        }
        
        case 'OFFER_FINISH':{
            return {
                ...state,
                offer:action.payload.data
            }
        }


        case 'VOTE_START': {
            return {
                ...state,
                idVote:undefined
            }
        }
        
        case 'VOTE_FINISH':{
            return {
                ...state,
                idVote:action.payload.data.idVote
            }
        }

        
        case 'INVENTORY_START':{
            return {
                ...state,
            }
        }
        case 'INVENTORY_FINISH':{
            return {
                ...state
            }
        }

        case 'OPTIN_TO_VOTE_START':{
            return {
                ...state,
            }
        }
        case 'OPTIN_TO_VOTE_FINISH':{
            return {
                ...state
            }
        }


        
        default:
            return state;
    }
}
