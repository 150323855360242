import React, { Component } from "react";
import Cookies from 'universal-cookie';
import { connect } from "react-redux";
import { Trans, withTranslation } from 'react-i18next';
import { updateVote, updateInventory } from "../../actions/RandomizerAction";
import { INVENTORY_ACTION, OFFER_TYPE } from "../../util/config/app-config";
import { trackSession } from "../../actions/TrackingActions";
import FadeLoader from "react-spinners/FadeLoader";
import Footer from "../footer/Footer";
import { getUrlWord } from "../../util/helpers/Helpers";

export class Claim extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state ={
      isClaimed:false,
      loader:false
    }

  }

  componentDidMount() {
    // let hash = this.props.location.hash;
    // hash = hash ? hash.replace(/(^#)/g, "") : "";
    let hash = getUrlWord(this.props.match.url, this.props.location.hash);

    this.setState({ ...this.state,hash });

    // if(!this.props.config.id){
    //   if (hash.includes("/")) {
    //     this.props.history.push(hash)
    //   } else if (hash.includes("__CURL_")) {
    //     this.props.history.push('');
    //   } else {
    //     // [hash removal] remove this after hash migration
    //     this.props.history.push('/#'+hash)
    //   }
    // }
  
  }

  checkAndRouteUrl() {
    let hash = getUrlWord(this.props.match.url, this.props.location.hash);
    if (hash.includes("/")) {
      this.props.history.push(hash)
    } else if (hash.includes("__CURL_")) {
      this.props.history.push('');
    } else {
      // [hash removal] remove this after hash migration
      this.props.history.push('/#' + hash)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if ((this.props !== prevProps) || (this.state !== prevState)) {
    //   // code
    //   console.log('changedddd props', (this.props !== prevProps));
    //   console.log('changedddd state', (this.state !== prevState));
    // }
    // console.log('curStateeee', this.state);
    // console.log('prevStateeee', prevState);

    if ((this.props !== prevProps) && prevProps && prevProps.config && prevProps.config.id) {
      const idComp = prevProps.config.id;
      const allowMultiUserEntry = localStorage.getItem(`${idComp}-allowMultiUserEntry`);
      console.log('allowww', allowMultiUserEntry);
      if (allowMultiUserEntry && allowMultiUserEntry == 'true') {
        this.checkAndRouteUrl();
      } else {
        const idOptin = localStorage.getItem(`${idComp}-idOptin`);
        const idVote = localStorage.getItem(`${idComp}-idVote`);
        const offer = JSON.parse(localStorage.getItem(`${idComp}-offer`));


        if (idOptin && offer && offer.id) {
          this.setState({ ...this.state, offer }, () => {
            console.log('updated', this.state);
          });
        } else {
          this.checkAndRouteUrl();
          // this.props.history.push('/#' + prevProps.config.hash);
        }
      }
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if(this.props.config  && this.props.idVote && this.props.tracking.flakeId && !prevProps.tracking.flakeId  ) {

  //       const fingerprint =  new Fingerprint().get()
  //       const idVote = this.props.idVote;
  //       const { flakeId } = this.props.tracking;
  //       let params = {type:"vote",idVote:idVote,sessionFlakeId:flakeId,fingerprint:fingerprint}
  //         this.props.dispatch( trackVote(this.props.config.id,params,false,'',this.props.dispatch)
  //       );
  //       this.setState({...this.state,isClaimed:true,loader:false})
  //       window.open(this.props.offer.offerUrl, "_blank");

  //   }

  //   if(this.props.config  && this.props.idVote && this.props.idOptin && !prevProps.idVote ) {
  //     const idVote = this.props.idVote;
  //     this.props.dispatch( updateOptinToVote(this.props.config.id,{idOptin:this.props.idOptin,idVote:idVote},this.props.dispatch));
  //   }
  

  // }



  handleSubmit = () => {

    // const cookies = new Cookies();
    // const idOptin = cookies.get('idOptin');
    // const idVote = cookies.get('idVote');
    const idComp = this.props.config.id;
    const idOptin =  localStorage.getItem(`${idComp}-idOptin`);
    const idVote =  localStorage.getItem(`${idComp}-idVote`);

    let { offer } = this.props;
    if (!offer || !offer.id) {
      offer = this.state.offer;
    }
    if (this.props.config.reduceOfferInventoryAction == INVENTORY_ACTION.BUTTTON_CLICK && idOptin && !idVote) {

      // if (this.props.config.id) {
      //   this.props.dispatch(trackSession(this.props.config.id, this.props.dispatch));
      // }

      const { id, idCompetition } = offer;
      let params = {
        selectionIds: [{ questionId: id, selectionId: 0 }],
        idCompetition: idCompetition
      };
      this.props.dispatch(updateVote(this.props, params, this.props.dispatch, true));

      if (offer.type === OFFER_TYPE.OFFER) {
        let payload = {
          id: offer.id,
          inventory: offer.offerInventory - 1
        };
        this.props.dispatch(updateInventory(idCompetition, payload, this.props.dispatch));
      }
    }
    window.open(offer.offerUrl, "_blank");
    
  }



  render() {
    let { config, offer } = this.props;
    if (!offer || !offer.id) {
      offer = this.state.offer;
    }

    let template = '';
    if (config && Object.keys(config).length > 0 && offer && offer.id) {
      const question = config.questions.find(
        val => val.type === "redemption_button"
      );

      template = (
        <div className={"offer claim"}>
        <div className={"title "}>
          <h1 className={""} style={{color: config ? config.primaryColor : ""}}><Trans i18nKey="you_got" />..</h1>
        </div>
        <div className={"gif text-center"}>
          {" "}
          <img
            src={offer.offerImageUrl}
            className="pl-logo img-fluid center-block"
          />
        </div>
    <div className={"description"}
      dangerouslySetInnerHTML={{ __html: offer.description }}
      style={{color: config ? config.primaryColor : ""}}></div>
       
    <div className="m-auto">
      <div className="mb-5">
          <div className="primary submit-optin cursor-pointer redemption-button"  style={{
            backgroundColor: config ? config.buttonColor : "",
            color: config ? config.secondaryColor : ""
          }} onClick={this.handleSubmit} >{question.questionText}</div>
          </div>
          </div>
          
           {/* <div className="">
                  <div
                    className="primary submit-optin cursor-pointer"
                    style={{
                      backgroundColor: config.buttonColor,
                      color: config.secondaryColor
                    }}
                    onClick={this.handleSubmit}
                  >
                    {config.enterCopy}
                  </div>
                </div> */}

          <Footer />
        </div>
      )
    }
    return   (<React.Fragment>
    {this.state.loader ? (
      <div className={"loaderCss"}>
        <div className={"loader"}>
          <FadeLoader
            size={150}
            color={"#ffffff"}
            loading={this.state.loader}
          />
        </div>
      </div>
    ) : null}<div>{template}</div>;
    </React.Fragment>)
  }
}

const mapStateToProps = (state, ownProps) => {
console.log('state',state)
  return {
    ...ownProps,
    config: state.default.data.config,
    locale: state.default.locale,
    offer: state.default.data.offer
  };
};

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(Claim);
