import React, { Component } from "react";
import { connect } from "react-redux";
import { getConfig } from "../actions/RandomizerAction";
import { loadLocale } from "../actions/LocaleAction";
import "./App.css";
import { initReactI18next, withTranslation } from "react-i18next";
import FadeLoader from "react-spinners/FadeLoader";
import Footer from "./footer/Footer";

import i18n from "i18next";
import { trackSession } from "../actions/TrackingActions";
import { getUrlWord, showEmbedFooter } from "../util/helpers/Helpers";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      hash: ""
    };

    this.resizeContainer = this.resizeContainer.bind(this);
  }

  resizeContainer = () => {
    const config = this.props.config;
    const iw = window.innerWidth;

    let useStyle = false;
    let useStyleBg = '';

    if(config.bgColor){
      document.getElementById('root').style.backgroundColor = config.bgColor;
      document.getElementById('root').style.minHeight = '100vh';
    }

    if (config.desktopBg && iw >= 1024) {
      // document.body.style.backgroundImage = `url(${config.desktopBg.file})`;
      useStyle = true;
      useStyleBg = config.desktopBg.file;
    } else if (config.tabletBg && iw < 1024 && iw >= 450) {
      // document.body.style.backgroundImage = `url(${config.tabletBg.file})`;
      useStyle = true;
      useStyleBg = config.tabletBg.file;
    } else if (config.mobileBg && iw < 450) {
      // document.body.style.backgroundImage = `url(${config.mobileBg.file})`;
      useStyle = true;
      useStyleBg = config.mobileBg.file;
    }

    // iphone/ipad background fix
    if (useStyle) {
      const styles = `
          body {
            background: url(${useStyleBg}) no-repeat center center fixed;
          }
          
          body:after{
            content:"";
            position:fixed; /* stretch a fixed position to the whole screen */
            top:0;
            height:100vh; /* fix for mobile browser address bar appearing disappearing */
            left:0;
            right:0;
            z-index:-1; /* needed to keep in the background */
            background: url(${useStyleBg}) center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
          }
        `;

      var styleSheet = document.createElement("style");
      styleSheet.innerText = styles;
      document.head.appendChild(styleSheet);
    }


    if (config.name) {
      document.title = config.name.replace(/(<([^>]+)>)/gi, " ").trim();
    }
    window.setTimeout(() => {
      let elHeight = document.body.scrollHeight;
      if (window.self !== window.top) {
        window.parent.postMessage(`benchvoteResize-${elHeight}`, "*");
      }
    }, 500);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.config.id && !prevProps.config.id) {
      this.resizeContainer();
      this.props.dispatch(trackSession(this.props.config.id, this.props.dispatch));
    }

    //to load language
    if (
      this.props.config &&
      this.props.config.localeId &&
      !prevProps.config.localeId
    ) {
      this.props.dispatch(
        loadLocale(this.props.config.localeId, this.props.dispatch)
      );
      this.props.dispatch(
        loadLocale(this.props.config.localeId, this.props.dispatch)
      );
    }
    if (
      this.props.locale.lang &&
      Object.keys(this.props.locale.lang).length !== 0 &&
      !prevProps.locale.lang.pick_now
    ) {
      setTimeout(() => {
        this.setState({ ...this.state, loader: false });
      }, 500);
      i18n.use(initReactI18next).init({
        lang: this.props.config.localeId,
        fallbackLng: this.props.config.localeId,
        debug: true,
        ns: ["common"],
        defaultNS: "common",
        interpolation: {
          escapeValue: false // not needed for react as it escapes by default
        },
        resources: {
          [this.props.config.localeId]: {
            common: this.props.locale.lang
          }
        }
      });
    }

    if (this.props.config.fontUrl && this.props.config.fontUrl != '') {
      // FONT CSS FILES
      var fileref = document.createElement("link");
      fileref.setAttribute("rel", "stylesheet");
      fileref.setAttribute("type", "text/css");
      fileref.setAttribute("href", this.props.config.fontUrl);
      if (typeof fileref != "undefined") {
        document.getElementsByTagName("head")[0].appendChild(fileref);
      }
    }

    if (this.props.config.cssContent && this.props.config.cssContent != ''
      && this.props.config.cssContentUrl && this.props.config.cssContentUrl != '') {
      // CSS FILES
      var fileref = document.createElement("link");
      fileref.setAttribute("rel", "stylesheet");
      fileref.setAttribute("type", "text/css");
      fileref.setAttribute("href", this.props.config.cssContentUrl);
      if (typeof fileref != "undefined") {
          document.getElementsByTagName("head")[0].appendChild(fileref);
      }
    }

    let allowMultiUserEntry = (this.props.config.allowMultiUserEntry);
    if (this.props.config.id) {
      localStorage.setItem(`${this.props.config.id}-allowMultiUserEntry`, allowMultiUserEntry);
    }

    if (allowMultiUserEntry) {
      localStorage.removeItem(`${this.props.config.id}-idOptin`);
      localStorage.removeItem(`${this.props.config.id}-idVote`);
      localStorage.removeItem(`${this.props.config.id}-offer`);
    }

  }

  componentDidMount() {
    // let hash = this.props.location.hash;
    // hash = hash ? hash.replace(/(^#)/g, "") : "";
    let hash = getUrlWord(this.props.match.url, this.props.location.hash);

    if (hash) {
      this.props.dispatch(getConfig(hash, this.props.dispatch));
      this.setState({ hash });
      this.setState({ ...this.state, loader: true });
    }
    this.resizeContainer();
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loader ? (
          <div className={"loaderCss"}>
            <div className={"loader"}>
              <FadeLoader
                size={150}
                color={"#ffffff"}
                loading={this.state.loader}
              />
            </div>
          </div>
        ) : null}
        <div className={"main-cont"}>{this.props.children}</div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    config: state.default.data.config,
    locale: state.default.locale,
  };
};


const mapDispatchToProps = dispatch => {
  return {
      dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
