import React, { Component } from "react";
import { connect } from "react-redux";
import Fingerprint from 'fingerprintjs'
import FadeLoader from "react-spinners/FadeLoader";
import moment from 'moment';


import {
  FormControl,
  HelpBlock,
  Checkbox,
  FormGroup,
  Radio,
  DropdownButton,
  MenuItem
} from "react-bootstrap";
import "./Home.css";
import { submitPlay } from "../../actions/RandomizerAction";
import { Trans, withTranslation } from "react-i18next";
import { getOffer,getConfig,updateInventory ,updateVote,updateOptinToVote} from "../../actions/RandomizerAction";
import {trackSession,trackVote} from "../../actions/TrackingActions";
import { OFFER_TYPE, INVENTORY_ACTION } from "../../util/config/app-config";
import { getUrlWord, setCustomInputStyling } from "../../util/helpers/Helpers";
import Footer from "../footer/Footer";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export class Home extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeOptinOther = this.handleChangeOptinOther.bind(this);
    this.handleSelectOptinOtherOption = this.handleSelectOptinOtherOption.bind(
      this
    );
    this.handleSelectOptinOtherOptionSingle = this.handleSelectOptinOtherOptionSingle.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAcceptTnc = this.handleAcceptTnc.bind(this);
    this.state = {
      loader:false,
      charLengthCustom: 'Characters Left: 2000/2000',
      optin: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: {
          countryData: {},
          number: '',
          status: false,
          value:''
        },
        address: "",
        postcode: "",
        state: "",
        other01: "",
        other02: "",
        other03: "",
        other04: "",
        other05: "",
        other06: "",
        other07: "",
        other08: "",
        other09: "",
        other10: "",
        tnc: false,
        marketing: false,
        others: [],
        dob: '',
        customDesc: '',
        confirmAdult: false
      },
      errStates: {
        firstname: false,
        lastname: false,
        email: false,
        mobile: false,
        address: false,
        postcode: false,
        state: false,
        other01: false,
        other02: false,
        other03: false,
        other04: false,
        other05: false,
        other06: false,
        other07: false,
        other08: false,
        other09: false,
        other10: false,
        tnc: false,
        dob: false,
        customDesc: false,
        confirmAdult: false
      }
    };

    this.preferredCountries = ['au','gb','us','br','ca','cn','de','in','id','my','nl','nz','pt','sg','es','th','vn','kr'];

  }

  handleAcceptTnc = () => {
    let newState = { ...this.state.optin };
    newState.tnc = !newState.tnc;
    this.setState({
      ...this.state,
      optin: newState,
    });
  };

  handleAcceptMarketing = () => {
    let newState = { ...this.state.optin };
    newState.marketing = !newState.marketing;
    this.setState({
      ...this.state,
      optin: newState
    });
  };

  selectMembership = value => {
    let newState = { ...this.state.optin };
    newState.member = value;
    this.setState({
      ...this.state,
      optin: newState
    });
  };

  handleChange = (field, e) => {
    let newState = { ...this.state.optin };
    newState[field] = e.target.value;
    this.setState({
      ...this.state,
      optin: newState
    });
  };

  handleAcceptConfirmAdult = () => {
    let newState = {...this.state.optin};
    newState.confirmAdult = !newState.confirmAdult;
    this.setState({
      ...this.state,
      optin: newState
    });
  }

  handleCustomDescriptionChange = (e) => {
    const {t} = this.props;
    let optin = this.state.optin;
    optin[e.target.name] = e.target.value;
    this.setState({
      optin
    });

    var maxLength = 2000;
    if (e.target.value.length > maxLength) {
      this.state.charLengthCustom =
        `${t('character_left')}: ` + e.target.value.substring(0, maxLength) + '/2000';
    } else {
      this.state.charLengthCustom =
      `${t('character_left')}: ` + (maxLength - e.target.value.length) + '/2000';
    }
  }

  handleSelectOptinOtherOptionSingle = (idOptinOther, ooo, e) => {
    const optin = {
      ...this.state.optin
    };
    if (!optin.others[idOptinOther]) {
      optin.others[idOptinOther] = {};
      optin.others[idOptinOther].selectedValues = [];
    }
    optin.others[idOptinOther].selectedValues = [ooo.id];
    this.setState({ optin });
  };

  handleSelectOptinOtherOption = (idOptinOther, ooo, e) => {
    const optin = {
      ...this.state.optin
    };
    if (!optin.others[idOptinOther]) {
      optin.others[idOptinOther] = {};
      optin.others[idOptinOther].selectedValues = [];
    }
    if (e.target.checked) {
      optin.others[idOptinOther].selectedValues.push(ooo.id);
    } else {
      optin.others[idOptinOther].selectedValues = optin.others[
        idOptinOther
      ].selectedValues.filter(o2 => o2 !== ooo.id);
    }
    this.setState({ optin });
  };

  handleChangeOptinOther = (idOptinOther, e) => {
    const optin = {
      ...this.state.optin
    };
    if (!optin.others[idOptinOther]) {
      optin.others[idOptinOther] = {};
    }
    optin.others[idOptinOther].value = e.target.value;
    this.setState({ optin });
  };

  validateEmail = email => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(email).toLowerCase()
    );
  };

  validateDob(dob) {
    var birthday = moment(dob);
    return birthday.isValid() && birthday.isBefore();
  }

  validateMobile = mobile => {
    return (mobile && !isNaN(mobile) && mobile.toString().length === 10);
  };

  memberYes = (e) => {
    let newState = {...this.state.optin};
    newState.memberYes = e.target.value;
    this.setState({
      ...this.state,
      optin: newState
    });
  }

  memberNo = (value) => {
    let newState = {...this.state.optin};
    newState.memberNo = value;
    this.setState({
      ...this.state,
      optin: newState
    });
  }

  renderCustomFields = (
    config,
    other01,
    other02,
    other03,
    other04,
    other05,
    other06,
    other07,
    other08,
    other09,
    other10,
    errors
  ) => {
    return (
      <div>
        {config.optinOther ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other01}
                placeholder={config.optinOtherLabel}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other01", e)}
              />
              {errors.other01 ? (
                <HelpBlock>
                  {config.optinOtherLabel} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther02 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other02}
                placeholder={config.optinOtherLabel02}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other02", e)}
              />
              {errors.other02 ? (
                <HelpBlock>
                  {config.optinOtherLabel02} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther03 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other03}
                placeholder={config.optinOtherLabel03}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other03", e)}
              />
              {errors.other03 ? (
                <HelpBlock>
                  {config.optinOtherLabel03} <Trans i18nKey="is_invalid" />.
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther04 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other04}
                placeholder={config.optinOtherLabel04}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other04", e)}
              />
              {errors.other04 ? (
                <HelpBlock>
                  {config.optinOtherLabel04} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther05 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other05}
                placeholder={config.optinOtherLabel05}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other05", e)}
              />
              {errors.other05 ? (
                <HelpBlock>
                  {config.optinOtherLabel05} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther06 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other06}
                placeholder={config.optinOtherLabel06}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other06", e)}
              />
              {errors.other06 ? (
                <HelpBlock>
                  {config.optinOtherLabel06} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther07 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other07}
                placeholder={config.optinOtherLabel07}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other07", e)}
              />
              {errors.other07 ? (
                <HelpBlock>
                  {config.optinOtherLabel07} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther08 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other08}
                placeholder={config.optinOtherLabel08}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other08", e)}
              />
              {errors.other08 ? (
                <HelpBlock>
                  {config.optinOtherLabel08} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther09 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other09}
                placeholder={config.optinOtherLabel09}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other09", e)}
              />
              {errors.other09 ? (
                <HelpBlock>
                  {config.optinOtherLabel09} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}

        {config.optinOther10 ? (
          <div className="optin-row">
            <div className="optin-col">
              <FormControl
                type="text"
                value={other10}
                placeholder={config.optinOtherLabel10}
                onFocus={this.sendParentScrollMessage}
                onChange={e => this.handleChange("other10", e)}
              />
              {errors.other10 ? (
                <HelpBlock>
                  {config.optinOtherLabel10} <Trans i18nKey="is_invalid" />
                </HelpBlock>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.config && this.props.config.reduceOfferInventoryAction == INVENTORY_ACTION.GIF_LOAD) {

      if (this.props.config.id && !prevProps.config.id) {
        this.props.dispatch(trackSession(this.props.config.id, this.props.dispatch));
      }

      if (this.props.config && this.props.idVote && this.props.tracking.flakeId && !prevProps.idVote) {

        const fingerprint = new Fingerprint().get()
        const idVote = this.props.idVote;
        const { flakeId } = this.props.tracking;
        let params = { type: "vote", idVote: idVote, sessionFlakeId: flakeId, fingerprint: fingerprint }
        this.props.dispatch(trackVote(this.props.config.id, params, this.props.history, this.state.hash, this.props.dispatch));

      }

      // if (this.props.config && this.props.idVote && this.props.idOptin && !prevProps.idOptin) {
      //   const idVote = this.props.idVote;
      //   this.props.dispatch(updateOptinToVote(this.props.config.id, { idOptin: this.props.idOptin, idVote: idVote }, this.props.dispatch));
      // }


      if (this.props.offer && this.props.offer.id && !prevProps.offer.id) {
        if (this.props.offer.type === OFFER_TYPE.OFFER) {
          const id = this.props.config.id;
          let payload = {
            id: this.props.offer.id,
            inventory: this.props.offer.offerInventory - 1
          };
          this.props.dispatch(updateInventory(id, payload, this.props.dispatch));
        }

        const { id, idCompetition } = this.props.offer;
        let params = {
          selectionIds: [{ questionId: id, selectionId: 0 }],
          idCompetition: idCompetition
        };
        this.props.dispatch(updateVote(this.props, params, this.props.dispatch, false));
      }
    }

    if ((this.props !== prevProps) && prevProps && prevProps.config && prevProps.config.id) {
      const idComp = prevProps.config.id;
      const allowMultiUserEntry = localStorage.getItem(`${idComp}-allowMultiUserEntry`);
      console.log('allowww', allowMultiUserEntry);
      if (!allowMultiUserEntry || allowMultiUserEntry == 'false') {
        const idOptin = localStorage.getItem(`${idComp}-idOptin`);
        const idVote = localStorage.getItem(`${idComp}-idVote`);
        const offer = JSON.parse(localStorage.getItem(`${idComp}-offer`));

        if (idOptin && offer && offer.id) {
          this.setState({ ...this.state, offer }, () => {
            console.log('updated', this.state);
          });
          let hash = getUrlWord(this.props.match.url, this.props.location.hash);
          if (hash.includes("/")) {
            this.props.history.push(hash + "/claim")
          } else if (hash.includes("__CURL_")) {
            this.props.history.push('claim');
          } else {
            // [hash removal] remove this after hash migration
            this.props.history.push('/claim/#' + hash)
          }
        }
      }
    }

  }

  componentDidMount() {
    // let hash = this.props.location.hash;
    // hash = hash ? hash.replace(/(^#)/g, "") : "";
    let hash = getUrlWord(this.props.match.url, this.props.location.hash);

    this.setState({ hash });
    if (hash) {
      this.props.dispatch(getConfig(hash, this.props.dispatch));
      this.setState({ hash });
      this.setState({ ...this.state });
    }
  }

  handleMobileNumber = (event)=>{
    let newState = { ...this.state.optin };
    newState['mobile'] = event;
    this.setState({
        ...this.state,
        optin: newState
    });
  }  

  renderCustomFieldsActual = (oo, others, otherIndex, config, t) => {
    console.log('custom-1', oo);
    switch (oo.type) {
      case "text":
        return (
          <div className="optin-col">
            <FormControl
              type="text"
              value={
                (others[oo.id] && others[oo.id].value) || ""
              }
              placeholder={oo.copy}
              onFocus={this.sendParentScrollMessage}
              onChange={e =>
                this.handleChangeOptinOther(oo.id, e)
              }
            />
          </div>
        );
      case "checkbox":
        return (
          <div className="optin-col text-left">
            <div
              class="fieldLabel"
              style={{ color: config.primaryColor }}
            >
              {oo.copy}
            </div>
            {oo.options
              .filter(o => !o.deleted)
              .map((ooo, index) => (
                <Checkbox
                  id={`checkGroup${otherIndex}${index}`}
                  className="custom-control custom-checkbox custom-control-inline"
                  onClick={e =>
                    this.handleSelectOptinOtherOption(
                      oo.id,
                      ooo,
                      e
                    )
                  }
                >
                  <label
                    style={{ color: config.primaryColor }}
                    class="custom-control-label"
                    for={`checkGroup${otherIndex}${index}`}
                    dangerouslySetInnerHTML={{
                      __html: ooo.copy
                    }}
                  />
                </Checkbox>
              ))}
          </div>
        );
      case "dropdown":
        return (
          <div className="optin-col text-left">
            <div
              class="fieldLabel"
              style={{ color: config.primaryColor }}
            >
              {oo.copy}
            </div>
            <DropdownButton
              title={
                others[oo.id] && others[oo.id].selectedValues
                  ? oo.options
                    .filter(o => !o.deleted)
                    .find(
                      ooo =>
                        others[oo.id].selectedValues[0] ===
                        ooo.id
                    ).copy
                  : t("select_an_option")
              }
            >
              {oo.options
                .filter(o => !o.deleted)
                .map(ooo => (
                  <MenuItem
                    onClick={e =>
                      this.handleSelectOptinOtherOptionSingle(
                        oo.id,
                        ooo,
                        e
                      )
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ooo.copy
                      }}
                    />
                  </MenuItem>
                ))}
            </DropdownButton>
          </div>
        );
      case "radio":
        return (
          <div className="optin-col text-left">
            <div
              class="fieldLabel"
              style={{ color: config.primaryColor }}
            >
              {oo.copy}
            </div>
            {oo.options
              .filter(o => !o.deleted)
              .map((ooo, index) => (
                <Radio
                  id={`radioGroup${otherIndex}${index}`}
                  className="custom-control custom-radio custom-control-inline"
                  name={`radioGroup${oo.id}`}
                  inline
                  onClick={e =>
                    this.handleSelectOptinOtherOptionSingle(
                      oo.id,
                      ooo,
                      e
                    )
                  }
                >
                  <label
                    style={{ color: config.primaryColor }}
                    class="custom-control-label"
                    for={`radioGroup${otherIndex}${index}`}
                    dangerouslySetInnerHTML={{
                      __html: ooo.copy
                    }}
                  />
                </Radio>
              ))}
          </div>
        );
    }
  }

  handleSubmit = () => {
    const {
      firstname,
      lastname,
      email,
      mobile,
      address,
      state,
      postcode,
      tnc,
      marketing,
      other01,
      other02,
      other03,
      other04,
      other05,
      other06,
      other07,
      other08,
      other09,
      other10,
      others,
      member,
      memberYes,
      memberNo, dob, customDesc, confirmAdult
    } = this.state.optin;
    const idCompetition = this.props.config.id;

    const {
      optinFirstname,
      optinLastname,
      optinPhone,
      optinEmail,
      optinAddress,
      optinPostcode,
      optinState,
      optinTncs,
      optinOther,
      optinOther02,
      optinOther03,
      optinOther04,
      optinOther05,
      optinOther06,
      optinOther07,
      optinOther08,
      optinOther09,
      optinOther10, optinDob, optinCustomDesc, optinConfirmAdult
    } = this.props.config;

    const otherData = this.props.config.otherOptins
      .filter(o => !o.deleted)
      .map(oo => {
        if (!oo.deleted) {
          if (oo.type === "text") {
            return {
              id: oo.id,
              type: oo.type,
              value: others[oo.id] ? others[oo.id].value : ""
            };
          } else if (
            oo.type === "checkbox" ||
            oo.type === "dropdown" ||
            oo.type === "radio"
          ) {
            return {
              id: oo.id,
              type: oo.type,
              values: others[oo.id] ? others[oo.id].selectedValues : []
            };
          }
        }
      });

    let es = {
      firstname: optinFirstname && !firstname,
      lastname: optinLastname && !lastname,
      email: optinEmail && !this.validateEmail(email),
      mobile: optinPhone && (!mobile || isNaN(mobile.value) || !mobile.status),
      address: optinAddress && !address,
      state: optinState && !state,
      postcode: optinPostcode && (!postcode || isNaN(postcode)),
      tnc: optinTncs && !tnc,
      other01: optinOther && !other01,
      other02: optinOther02 && !other02,
      other03: optinOther03 && !other03,
      other04: optinOther04 && !other04,
      other05: optinOther05 && !other05,
      other06: optinOther06 && !other06,
      other07: optinOther07 && !other07,
      other08: optinOther08 && !other08,
      other09: optinOther09 && !other09,
      other10: optinOther10 && !other10,
      member,
      memberYes,
      memberNo,
      dob: optinDob && !this.validateDob(dob),
      confirmAdult: optinConfirmAdult && !confirmAdult,
      customMandatoryOptin: false
    }

    const otherOptins = this.props.config.otherOptins;

    if (otherOptins && otherOptins.length > 0) {
        otherOptins.forEach((oo, key) => {
            if (otherOptins.hasOwnProperty(key) && !otherOptins[key].deleted
                && otherOptins[key].mandatory) {
                switch (otherOptins[key].type) {
                    case 'text':
                    case 'date':
                        if (!others[otherOptins[key].id] || !others[otherOptins[key].id].hasOwnProperty('value')
                            || others[otherOptins[key].id].value == null || !others[otherOptins[key].id].value
                            || others[otherOptins[key].id].value.trim() == "") {
                            es.customMandatoryOptin = true;
                            otherOptins[key].hasError = true;
                        } else {
                            otherOptins[key].hasError = false;
                        }
                        break;
                    case 'checkbox':
                    case 'dropdown':
                    case 'radio':
                        if (!others[otherOptins[key].id] || !others[otherOptins[key].id].hasOwnProperty('selectedValues')
                            || others[otherOptins[key].id].selectedValues.length < 1 || others[otherOptins[key].id].selectedValues[0] == "") {
                            es.customMandatoryOptin = true;
                            otherOptins[key].hasError = true;
                        } else {
                            otherOptins[key].hasError = false;
                        }
                        break;
                }
            }
        });
    }

    window.scrollTo(0, 0);

    if (
      es.firstname ||
      es.lastname ||
      es.email ||
      es.mobile ||
      es.address ||
      es.state ||
      es.postcode ||
      es.tnc || es.dob || es.confirmAdult || es.customMandatoryOptin
    ) {
      this.setState({
        ...this.state,
        errStates: es
      });
    } else {
      const { hash } = this.state;

      this.props.dispatch(
        submitPlay(
          this.props.config.reduceOfferInventoryAction == INVENTORY_ACTION.BUTTTON_CLICK,
          hash,
          {
            firstname,
            lastname,
            email,
            mobile:mobile.value?(mobile.number).replace(/\s+/g, ''):'',
            address,
            state,
            postcode,
            tnc,
            optin: marketing,
            other01,
            other02,
            other03,
            other04,
            other05,
            other06,
            other07,
            other08,
            other09,
            other10,
            member,
            memberYes,
            memberNo,
            dob, confirmAdult, customDesc,
            idCompetition,
            other: otherData
          },
          this.props
        )
      );

      // this.props.dispatch(
      //   getOffer(this.props.history, this.props.config.id, this.state.hash, this.props.dispatch)
      // );
      
      // const reduceOfferOnBtnClick = (this.props.config.reduceOfferInventoryAction == INVENTORY_ACTION.BUTTTON_CLICK);

      // if (reduceOfferOnBtnClick) {
      //   this.props.history.push("/offer#" + this.state.hash);
      // }

      this.setState({
        ...this.state,
        loader:true,
        errStates: es
      });
    }
  };

  render() {
    let { config } = this.props;
    let voteCont = "";
    if (Object.keys(config).length > 0) {
      let t = this.props.t;

      const {
        firstname,
        lastname,
        email,
        mobile,
        address,
        state,
        postcode,
        tnc,
        marketing,
        other: other01,
        other02,
        other03,
        other04,
        other05,
        other06,
        other07,
        other08,
        other09,
        other10,
        member,
        memberNo,
        others, dob, customDesc, confirmAdult
      } = this.state.optin;

      const errors = this.state.errStates;

      const anyField =
        !!config.optinFirstname ||
        !!config.optinLastname ||
        !!config.optinPhone ||
        !!config.optinEmail ||
        !!config.optinAddress ||
        !!config.optinPostcode ||
        !!config.optinState ||
        !!config.optinTncs ||
        !!config.optinOther ||
        !!config.optinOther02 ||
        !!config.optinOther03 ||
        !!config.optinOther04 ||
        !!config.optinOther05 ||
        !!config.optinOther06 ||
        !!config.optinOther07 ||
        !!config.optinOther08 ||
        !!config.optinOther09 ||
        !!config.optinOther10 ||
        !!config.optinDob ||
        !!config.optinCustomDesc ||
        !!config.optinConfirmAdult;

      let leadCaptureFields = [
        {
          id: 'optinFirstname',
          value: firstname,
          model: 'firstname',
          placeholder: 'first_name',
          type: 'text',
          error: {
            field: 'firstname',
            message: 'err_first_name'
          }
        },
        {
          id: 'optinLastname',
          value: lastname,
          model: 'lastname',
          placeholder: 'last_name',
          type: 'text',
          error: {
            field: 'lastname',
            message: 'err_last_name'
          }
        },
        {
          id: 'optinEmail',
          value: email,
          model: 'email',
          placeholder: 'email_address',
          type: 'text',
          error: {
            field: 'email',
            message: 'email_addres_err'
          }
        },
        {
          id: 'optinPhone',
          value: mobile,
          model: 'phone',
          type: 'text',
          error: {
            field: 'mobile',
            message: 'mobile_number_err'
          }
        },
        {
          id: 'optinDob',
          value: dob,
          model: 'dob',
          placeholder: 'dob',
          type: 'date',
          error: {
            field: 'dob',
            message: 'dob_err'
          }
        },
        {
          id: 'optinAddress',
          value: address,
          model: 'address',
          placeholder: 'address',
          type: 'text',
          error: {
            field: 'address',
            message: 'address_err'
          }
        },
        {
          id: 'optinState',
          value: state,
          model: 'state',
          placeholder: 'state',
          type: 'text',
          error: {
            field: 'state',
            message: 'state_err'
          }
        },
        {
          id: 'optinPostcode',
          value: postcode,
          model: 'postcode',
          placeholder: 'postcode',
          type: 'text',
          error: {
            field: 'postcode',
            message: 'postcode_err'
          }
        },
        {
          id: 'optinCustomDesc',
          value: customDesc,
          model: 'customDesc',
          placeholder: 'comments',
          type: 'text',
          error: {
            field: 'customDescError',
            message: 'comments_error'
          }
        },
      ];

      let otherLCFields = [
        {
          id: 'optinMember',
          value: member,
          placeholder: 'comments',
          model: 'member',
          type: 'text',
        },
        {
          id: 'optinTncs',
          value: tnc,
          model: 'tnc',
          placeholder: 'confirm_adult',
          type: 'text',
          error: {
            field: 'tncError',
            message: 'terms_condition_18'
          }
        },
        {
          id: 'optinMarketing',
          value: marketing,
          model: 'optin',
          placeholder: 'confirm_adult',
          type: 'text'
        },
        {
          id: 'optinConfirmAdult',
          value: confirmAdult,
          model: 'confirmAdult',
          placeholder: 'confirm_adult',
          type: 'text',
          error: {
            field: 'confirmAdultError',
            message: 'confirm_adult_err'
          }
        },
      ];

      if (config.isLCFOrderingEnabled) {
        leadCaptureFields = leadCaptureFields.concat(otherLCFields);
      }

      if (config.otherOptins && config.otherOptins.length > 0) {
        config.otherOptins.forEach(function (oo) {
          if (oo.disabled || oo.deleted) {
            return;
          }
          oo.isCustom = true;
          oo.model = 'custom';
          // if (oo.index != null) {
          //   console.log('index', oo);
          //   that.leadCaptureFields[oo.index - 1] = oo;
          // } else {
          console.log('noindex', oo)
          leadCaptureFields.push(oo);
          // }
        });
      }

      if (!config.isLCFOrderingEnabled) {
        leadCaptureFields = leadCaptureFields.concat(otherLCFields);
      }

      // leadCaptureFields.forEach((field, fieldIndex) => {
      //   if (!field.isCustom) {
      //     if (!config[field.id] || config[field.id] == 0 || config[field.id] == null) {
      //       leadCaptureFields.splice(fieldIndex, 1);
      //     }
      //   }
      // })

      for (let i = leadCaptureFields.length - 1; i >= 0; i -= 1) {
        let field = leadCaptureFields[i];
        if (!field.isCustom) {
          if (!config[field.id] || config[field.id] == 0 || config[field.id] == null) {
            leadCaptureFields.splice(i, 1);
          }
        }
      }

      let leadCaptureFieldsHolder = [];
      let leadCaptureFieldsSorted = [];

      if (config.isLCFOrderingEnabled) {
        leadCaptureFields.forEach((field, fieldIndex) => {
          if (!field.isCustom) {
            if (config[field.id] && config[field.id] > 0 && config[field.id] != null) {
              leadCaptureFieldsSorted.push([field.id, config[field.id]]);
            }
          } else if (field.isCustom) {
            if (!field.disabled && !field.deleted) {
              leadCaptureFieldsSorted.push([field.id, field.index]);
            }
          }
        });

        leadCaptureFieldsSorted.sort(function (a, b) {
          return a[1] - b[1];
        });

        leadCaptureFieldsSorted.forEach((field, fieldIndex) => {
          var searchField = leadCaptureFields.find(function (lcf) {
            return lcf.id === field[0];
          });
          if (searchField) {
            leadCaptureFieldsHolder.push(searchField);
          }
        });

        leadCaptureFields = leadCaptureFieldsHolder;
      } else {
        leadCaptureFields = leadCaptureFields;
      }

      voteCont = (
        <div
          className="tx d-flex flex-column"
          id="app-page-3"
          style={{ color: config.primaryColor }}
        >
            {setCustomInputStyling(config.primaryColor ? config.primaryColor : '#fff')}
            {config.logoBg || (config.sponsorsBg && config.sponsorsTop) ? (
            <div className={'intro'}>
              <div>
                {config.logoBg ? (
                  <div className={'logos-cont'}>
                    <img src={config.logoBg.file} className={'logo-image'}/>
                  </div>
                ) : null}
                {config.sponsorsBg && config.sponsorsTop ? (config.sponsorsLink && config.sponsorsLink != '') ? (
                  <a href={config.sponsorsLink} target="_blank">
                    <div className={'logos-cont'}>
                      <img src={config.sponsorsBg.file} className={'logo-image sponsors'}/>
                    </div>
                  </a>
                ) : (
                  <div className={'logos-cont'}>
                    <img src={config.sponsorsBg.file} className={'logo-image sponsors'}/>
                  </div>
                ) : null}
              </div>
            </div>
            ) : null}
          <div
            style={{
              color: config ? config.primaryColor : ""
            }}
            className="vote-cont"
          >
            <h1
              style={{ color: config ? config.primaryColor : "" }}
              dangerouslySetInnerHTML={{ __html: config.detail }}
              className={"mini-site-title"}
            />
            <p
              style={{ color: config ? config.primaryColor : "" }}
              dangerouslySetInnerHTML={{
                __html: config.description
              }}
              className={"site-description mini-site-description"}
            />
            <h1
              style={{ color: config ? config.primaryColor : "" }}
              dangerouslySetInnerHTML={{__html: config.lastPageCopy}}
              className={"site-description"}
            />
          </div>

          {config.id && (
            <div className="optin-cont">
              {leadCaptureFields && leadCaptureFields.length > 0 ? (
                <div>
                  {leadCaptureFields
                    .filter(o => !o.disabled && !o.deleted)
                    .map((oo, otherIndex) => {
                      switch (oo.model) {
                        case "phone":
                          return (
                            <div className="optin-row">
                              <div className="optin-col">
                                <IntlTelInput
                                  containerClassName="intl-tel-input"
                                  inputClassName="form-control"
                                  value={mobile.value}
                                  preferredCountries={this.preferredCountries}
                                  defaultCountry={(config.phoneDefaultCountry && config.phoneDefaultCountry != null)
                                                  ? config.phoneDefaultCountry : 'au'}
                                  placeholder={t('mobile_number')}
                                  separateDialCode={true}
                                  onPhoneNumberChange={(status, value, countryData, number) => this.handleMobileNumber({ status, value, countryData, number })}
                                  onSelectFlag={(value, countryData, number, status) => this.handleMobileNumber({ value, countryData, number, status })}
                                />
                                {errors.mobile ? (
                                  <HelpBlock>
                                    <Trans i18nKey="mobile_number_err" />
                                  </HelpBlock>
                                ) : null}
                              </div>
                            </div>
                          )
                        case "customDesc":
                          return (

                            <div className="optin-row">
                              <div className='optin-col'>
                                <FormControl
                                  componentClass='textarea'
                                  style={{ height: 120 }}
                                  placeholder={t('comments')}
                                  onFocus={this.sendParentScrollMessage}
                                  onChange={this.handleCustomDescriptionChange}
                                  name='customDesc'
                                  value={customDesc}
                                  maxLength='2000'
                                />
                                <div style={{ marginTop: 5 }}>{this.state.charLengthCustom}</div>
                              </div>
                            </div>
                          )
                        case "confirmAdult":
                          return (
                            <div className="optin-row">
                              <div className=''>
                                <Checkbox id="optinConfirmAdult" className="custom-control custom-checkbox"
                                  checked={confirmAdult}
                                  onClick={this.handleAcceptConfirmAdult}
                                >
                                  <label class="custom-control-label" style={{ color: config.primaryColor }}
                                    for="optinConfirmAdult">
                                    <Trans i18nKey="confirm_adult" />
                                  </label>
                                </Checkbox>
                                {errors.confirmAdult ? (
                                  <HelpBlock className="text-center">
                                    <Trans i18nKey="confirm_adult_err" /></HelpBlock>
                                ) : null}
                              </div>
                            </div>
                          )
                        case "optin":
                          return (
                            <div className="optin-row">
                              <div className="">
                                <Checkbox
                                  id="optinMarketingCopy"
                                  className="custom-control custom-checkbox"
                                  checked={marketing}
                                  onClick={this.handleAcceptMarketing}
                                >
                                  <label
                                    class="custom-control-label"
                                    style={{ color: config.primaryColor }}
                                    for="optinMarketingCopy"
                                    dangerouslySetInnerHTML={{
                                      __html: config.optinMarketingCopy
                                    }}
                                  />
                                </Checkbox>
                              </div>
                            </div>
                          )
                        case "tnc":
                          return (
                            <div className="optin-row">
                              <div className="">
                                <Checkbox
                                  id="optinTncsCopy"
                                  className="custom-control custom-checkbox"
                                  checked={tnc}
                                  onClick={this.handleAcceptTnc}
                                >
                                  <label
                                    className="custom-control-label"
                                    style={{ color: config.primaryColor }}
                                    htmlFor="optinTncsCopy"
                                    dangerouslySetInnerHTML={{
                                      __html: config.optinTncsCopy
                                    }}
                                  />
                                </Checkbox>
                                {errors.tnc ? (
                                  <HelpBlock>
                                    <Trans i18nKey="terms_condition_err" />
                                  </HelpBlock>
                                ) : null}
                              </div>
                            </div>
                          )
                        case "dob":
                          return (
                            <div className="optin-row">
                              <div className='text-center' style={{ color: config ? config.primaryColor : '' }}>
                                <Trans i18nKey="dob" />
                              </div>
                              <div className='optin-col'>
                                <FormControl
                                  className='date_of_birth'
                                  type='date'
                                  placeholder="dd/mm/yyyy"
                                  name='dob'
                                  onFocus={this.sendParentScrollMessage}
                                  onChange={(e) => this.handleChange('dob', e)}
                                  value={dob}
                                />
                                {errors.dob ? (
                                  <HelpBlock><Trans i18nKey="dob_err" /></HelpBlock>
                                ) : null}
                              </div>
                            </div>
                          )
                        case "member":
                          return (
                            <div className="optin-row">
                              <div className="optin-col text-left">
                                <div
                                  className="fieldLabel"
                                  style={{ color: config.primaryColor }}
                                >
                                  {config.optinMemberLabel}
                                </div>
                                <FormGroup>
                                  <Radio
                                    id="membershipYes"
                                    className="custom-control custom-radio custom-control-inline"
                                    name="radioGroup"
                                    onClick={() => this.selectMembership(true)}
                                    inline
                                  >
                                    <label
                                      class="custom-control-label"
                                      style={{ color: config.primaryColor }}
                                      for="membershipYes"
                                    >
                                      <Trans i18nKey="yes" />
                                    </label>
                                  </Radio>{" "}
                                  <Radio
                                    id="membershipNo"
                                    className="custom-control custom-radio custom-control-inline"
                                    name="radioGroup"
                                    onClick={() => this.selectMembership(false)}
                                    inline
                                  >
                                    <label
                                      class="custom-control-label"
                                      style={{ color: config.primaryColor }}
                                      for="membershipNo"
                                    >
                                      <Trans i18nKey="no" />
                                    </label>
                                  </Radio>
                                </FormGroup>
                              </div>
                              {config.optinMember && member === true ? (
                                <div className="optin-row">
                                  <div className="optin-col">
                                    <FormControl
                                      type="text"
                                      value={memberNo}
                                      placeholder={config.optinMemberYesCopy}
                                      onFocus={this.sendParentScrollMessage}
                                      onChange={this.memberYes}
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {config.optinMember && member === false ? (
                                <div className="optin-row">
                                  <div className="optin-col text-left">
                                    <div
                                      class="fieldLabel"
                                      style={{ color: config.primaryColor }}
                                    >
                                      {config.optinMemberNoCopy}
                                    </div>
                                    <FormGroup>
                                      <Radio
                                        id="optinMemberNoCopyYes"
                                        className="custom-control custom-radio custom-control-inline"
                                        name="MemberNoGroup"
                                        onClick={() => this.memberNo(true)}
                                        inline
                                      >
                                        <label
                                          class="custom-control-label"
                                          style={{ color: config.primaryColor }}
                                          for="optinMemberNoCopyYes"
                                        >
                                          <Trans i18nKey="yes" />
                                        </label>
                                      </Radio>{" "}
                                      <Radio
                                        id="optinMemberNoCopyNo"
                                        className="custom-control custom-radio custom-control-inline"
                                        name="MemberNoGroup"
                                        onClick={() => this.memberNo(true)}
                                        inline
                                      >
                                        <label
                                          class="custom-control-label"
                                          style={{ color: config.primaryColor }}
                                          for="optinMemberNoCopyNo"
                                        >
                                          <Trans i18nKey="no" />
                                        </label>
                                      </Radio>
                                    </FormGroup>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          )
                        case "custom":
                          return (
                            <div className="optin-row">
                              {this.renderCustomFieldsActual(oo, others, otherIndex, config, t)}
                              {errors.customMandatoryOptin && oo.hasError ? (
                                  <HelpBlock className='text-center'><Trans i18nKey="field_required" /></HelpBlock>
                              ) : null}
                            </div>
                          )
                        default:
                          return (
                            <div className="optin-row">
                              <div className="optin-col">
                                <FormControl
                                  type={oo.type}
                                  value={oo.value}
                                  placeholder={t(oo.placeholder)}
                                  onFocus={this.sendParentScrollMessage}
                                  onChange={e => this.handleChange(oo.model, e)}
                                />
                                {errors[oo.error.field] ? (
                                  <HelpBlock>
                                    <Trans i18nKey={oo.error.message} />
                                  </HelpBlock>
                                ) : null}
                              </div>
                            </div>
                          )
                      }
                    })}
                </div>
              ) : null}

              <div className="optin-row last">
                <div className="mb-5">
                  <div
                    className="primary submit-optin cursor-pointer"
                    style={{
                      backgroundColor: config.buttonColor,
                      color: config.secondaryColor
                    }}
                    onClick={this.handleSubmit}
                  >
                    {config.enterCopy}
                  </div>
                </div>
              </div>

            {(config.sponsorsBg && !config.sponsorsTop) ? (
            <div className={'intro sponsors-footer'}>
              <div>
                {(config.sponsorsLink && config.sponsorsLink != '') ? (
                  <a href={config.sponsorsLink} target="_blank">
                    <div className={'logos-cont'}>
                      <img src={config.sponsorsBg.file} className={'logo-image sponsors'}/>
                    </div>
                  </a>
                ) : (
                  <div className={'logos-cont'}>
                    <img src={config.sponsorsBg.file} className={'logo-image sponsors'}/>
                  </div>
                )}
              </div>
            </div>
            ) : null}
            </div>
          )}
        </div>
      );
    }
    return (<React.Fragment>
       {this.state.loader ? (
          <div className={"loaderCss"}>
            <div className={"loader"}>
              <FadeLoader
                size={150}
                color={"#ffffff"}
                loading={this.state.loader}
              />
            </div>
          </div>
        ) : null}
        <div className={"parent-vote-cont"}>{voteCont}</div>
        <Footer />
    </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log('state--->',state);
  return {
    ...ownProps,
    config: state.default.data.config,
    locale: state.default.locale,
    offer: state.default.data.offer,
    tracking: state.default.tracking,
    idOptin:state.default.data.idOptin,
    idVote:state.default.data.idVote
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Home));
