import Cookies from 'universal-cookie';
import { getConfigData } from "../util/api_calls/RandomizerApi";
import { decodeOtherLanguage } from "../util/helpers/Helpers";
import {
  playNowApiCall,
  fetchOfferCall,
  voteCall,updateInventoryAPICall,updateOptinToVoteAPICall
} from "../util/api_calls/RandomizerApi";
import Fingerprint from 'fingerprintjs'
import { trackSession, trackVote } from "../actions/TrackingActions";
import { trackOptin } from '../actions/TrackingActions';
import { showEmbedFooter } from '../util/helpers/Helpers';

const getConfig = (hash, dispatch) => {
  getConfigData(
    hash,
    payload => {
      const { data } = payload;
      if (
        data.hasOwnProperty("legacyCharEncoding") &&
        data.legacyCharEncoding == 0
      ) {
        data.detail = decodeOtherLanguage(data.detail);
        data.description = decodeOtherLanguage(data.description);
        data.lastPageCopy = decodeOtherLanguage(data.lastPageCopy);
        data.thanksCopy = decodeOtherLanguage(data.thanksCopy);
        data.drawCopy = decodeOtherLanguage(data.drawCopy);
      }
      dispatch(getConfigFinish(payload));
    },
    error => {
      dispatch(getConfigError(error));
    }
  );

  return {
    type: "GET_CONFIG_START",
    payload: {}
  };
};

const getConfigFinish = payload => {
  return {
    type: "GET_CONFIG_FINISH",
    payload: {
      ...payload
    }
  };
};

const getConfigError = error => {
  let payload = {};
  if (error.constructor.name === "Error") {
    payload = {
      errorMessages: ["There was an error, please contact support."]
    };
  } else {
    payload = { ...error };
  }
  return {
    type: "GET_CONFIG_ERROR",
    payload: payload
  };
};

const submitPlay = (reduceOfferOnBtnClick, hash, formData, props) => {
  playNowApiCall(
    formData,
    payload => {
      props.dispatch(playNowFinish(reduceOfferOnBtnClick, formData.optin, hash, payload, props));
    },
    error => {
      props.dispatch(playNowError(error));
    }
  );

  return {
    type: "SUBMIT_START",
    payload: {}
  };
};

const playNowFinish = (reduceOfferOnBtnClick, optin, hash, payload, props) => {
  const cookies = new Cookies();
  cookies.set('idOptin', Number(payload.data.idOptin), { path: '/' });
  localStorage.setItem(`${props.config.id}-idOptin`, Number(payload.data.idOptin));

  if (optin) {
    const { flakeId } = props.tracking;
    props.dispatch(trackOptin(props.config.id, payload.data.idOptin, flakeId, props.dispatch));
  }

  props.dispatch(
    getOffer(props.history, props.config.id, hash, props.dispatch, reduceOfferOnBtnClick)
  );

  return {
    type: "SUBMIT_FINISH",
    payload: {
      ...payload
    }
  };
};

const playNowError = error => {
  let payload = {};
  if (error.constructor.name === "Error") {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ["There was an error, please contact support."]
    };
  } else {
    payload = { ...error };
  }
  return {
    type: "SUBMIT_ERROR",
    payload: payload
  };
};

const getOffer = (history, id, hash, dispatch, reduceOfferOnBtnClick) => {
  fetchOfferCall(
    id,
    payload => {
      dispatch(offerFinish(history, hash, payload, reduceOfferOnBtnClick));
    },
    error => {
      dispatch(offerError(error));
    }
  );

  return {
    type: "OFFER_START",
    payload: {}
  };
};

const offerFinish = (history, hash, payload, reduceOfferOnBtnClick) => {
  console.log('offerrrrr', payload);
  if (payload && payload.data && payload.data.idCompetition) {
    localStorage.setItem(`${payload.data.idCompetition}-offer`, JSON.stringify(payload.data));
  }
  if (reduceOfferOnBtnClick) {
    const hasEmbedFooter = showEmbedFooter();
    if (hash.includes("/")) {
      if(hasEmbedFooter){
        history.push(hash + "/offer/?embed=true");
      }else{
        history.push(hash + "/offer");
      }
    } else if (hash.includes("__CURL_")) {
      if (hasEmbedFooter) {
        history.push(`offer?embed=true`);
      } else {
        history.push(`offer`);
      }
    } else {
      if(hasEmbedFooter){
        history.push("/offer/?embed=true#" + hash);
      }else{
        history.push("/offer#" + hash);
      }
    }
   
  }
  return {
    type: "OFFER_FINISH",
    payload: { ...payload }
  };
};

const offerError = error => {
  let payload = {};
  if (error.constructor.name === "Error") {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ["There was an error, please contact support."]
    };
  } else {
    payload = { ...error };
  }
  return {
    type: "OFFER_ERROR",
    payload: payload
  };
};

const updateVote = (props, param, dispatch, reduceOfferOnBtnClick) => {
  voteCall(
    param,
    payload => {
      dispatch(voteFinish(props, payload, reduceOfferOnBtnClick));
    },
    error => {
      dispatch(voteError(error));
    }
  );

  return {
    type: "VOTE_START",
    payload: {}
  };
};

const voteFinish = (props, payload, reduceOfferOnBtnClick) => {
  const idVote = payload.data.idVote;
  const cookies = new Cookies();
  cookies.set('idVote', idVote, { path: '/' });
  localStorage.setItem(`${props.config.id}-idVote`, idVote);
  const idOptin = cookies.get('idOptin');
  if (idVote && props.config && idVote && idOptin) {
    props.dispatch(updateOptinToVote(props.config.id, { idOptin: idOptin, idVote: idVote }, props.dispatch));
  }
  return {
    type: "VOTE_FINISH",
    payload: { ...payload }
  };
};

const voteError = error => {
  let payload = {};
  if (error.constructor.name === "Error") {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ["There was an error, please contact support."]
    };
  } else {
    payload = { ...error };
  }
  return {
    type: "VOTE_ERROR",
    payload: payload
  };
};



const updateEvent = (history,param, dispatch) => {
  voteCall(
    param,
    payload => {
      dispatch(eventFinish(history,payload));
    },
    error => {
      dispatch(eventError(error));
    }
  );

  return {
    type: "EVENT_START",
    payload: {}
  };
};

const eventFinish = (history,payload) => {
  //history.push("/offer");
  return {
    type: "EVENT_FINISH",
    payload: { ...payload }
  };
};

const eventError = error => {
  let payload = {};
  if (error.constructor.name === "Error") {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ["There was an error, please contact support."]
    };
  } else {
    payload = { ...error };
  }
  return {
    type: "VOTE_ERROR",
    payload: payload
  };
};

const updateInventory = (id,param, dispatch) => {
  updateInventoryAPICall(
    id,
    param,
    payload => {
      dispatch(inventoryFinish(payload));
    },
    error => {
      dispatch(inventoryError(error));
    }
  );
  return {
    type: "INVENTORY_START",
    payload: {}
  };
};

const inventoryFinish = payload => {
  return {
    type: "INVENTORY_FINISH",
    payload: { ...payload }
  };
};

const inventoryError = error => {
  let payload = {};
  if (error.constructor.name === "Error") {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ["There was an error, please contact support."]
    };
  } else {
    payload = { ...error };
  }
  return {
    type: "VOTE_ERROR",
    payload: payload
  };
};


const updateOptinToVote = (id,param, dispatch) => {
  updateOptinToVoteAPICall(
    id,
    param,
    payload => {
      dispatch(updateOptinToVoteFinish(payload));
    },
    error => {
      dispatch(updateOptinToVoteError(error));
    }
  );
  return {
    type: "OPTIN_TO_VOTE_START",
    payload: {}
  };
};

const updateOptinToVoteFinish = payload => {
  return {
    type: "OPTIN_TO_VOTE_FINISH",
    payload: { ...payload }
  };
};

const updateOptinToVoteError = error => {
  let payload = {};
  if (error.constructor.name === "Error") {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ["There was an error, please contact support."]
    };
  } else {
    payload = { ...error };
  }
  return {
    type: "OPTIN_TO_VOTE_ERROR",
    payload: payload
  };
};

export { getConfig, submitPlay, getOffer, updateVote, updateInventory,updateEvent,updateOptinToVote };
