import {trackSessionApiCall, trackVoteApiCall} from '../util/api_calls/RandomizerApi';
import Fingerprint from 'fingerprintjs';

const trackSession = (idCompetition, dispatch) => {

  trackSessionApiCall(
    idCompetition,
    {type: "session", fingerprint: new Fingerprint().get()},
    payload => {
      dispatch(trackSessionFinish(payload))
    },
    error => {
      dispatch(trackSessionError(error))
    }
  );

  return {
    type: 'TRACK_SESSION_START',
    payload: {}
  }
}

const trackSessionFinish = payload => {

  return {
    type: 'TRACK_SESSION_FINISH',
    payload: {
      ...payload
    }
  }
}

const trackSessionError = error => {
  let payload = {}
  if (error.constructor.name === 'Error') {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ['There was an error, please contact support.']
    };
  } else {
    payload = {...error};
  }
  return {
    type: "TRACK_SESSION_ERROR",
    payload: payload
  }
}

const trackOptin = (idCompetition, idOptin, flakeId, dispatch) => {

  trackVoteApiCall(
    idCompetition,
    {type: "optin", idOptin, fingerprint: new Fingerprint().get(), sessionFlakeId: flakeId},
    payload => {
      dispatch(trackOptinFinish(payload))
    },
    error => {
      dispatch(trackOptinError(error))
    }
  );

  return {
    type: 'TRACK_OPTIN_START',
    payload: {}
  }
};

const trackOptinFinish = payload => {
  return {
    type: 'TRACK_OPTIN_FINISH',
    payload: {
      ...payload
    }
  }
};

const trackOptinError = error => {

  console.error("error", error);
  let payload = {}
  if (error.constructor.name === 'Error') {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ['There was an error, please contact support.']
    };
  } else {
    payload = {...error};
  }
  return {
    type: "TRACK_OPTIN_ERROR",
    payload: payload
  }
};

const trackVote = (idCompetition,params,history,hash,dispatch) => {

  trackVoteApiCall(
    idCompetition,
    params,
    payload => {
      dispatch(trackVoteFinish(payload,history,hash))
    },
    error => {
      dispatch(trackVoteError(error))
    }
  );

  return {
    type: 'TRACK_VOTE_START',
    payload: {}
  }
}

const trackVoteFinish = (payload,history,hash) => {
  if(history){
    if (hash.includes("/")) {
      history.push(hash + '/offer')
    } else if (hash.includes("__CURL_")) {
      history.push(`offer`);
    } else {
      history.push('/offer/#'+hash)
    }
  }
  return {
    type: 'TRACK_VOTE_FINISH',
    payload: {
      ...payload
    }
  }
}

const trackVoteError = error => {


  let payload = {}
  if (error.constructor.name === 'Error') {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ['There was an error, please contact support.']
    };
  } else {
    payload = {...error};
  }
  return {
    type: "TRACK_VOTE_ERROR",
    payload: payload
  }
}


export {trackSession, trackOptin, trackVote}
