import { buildHeaders,generateUrlApiV2,generateUrl } from '../helpers/MiddlewareHelpers';
import axios from 'axios';



const getConfigData= (hash, successCallback, catchCallback) => {
    const headers = buildHeaders();
    // const url = generateUrl('/competition/hash/'+hash);
    const url = generateUrlApiV2('/competition/hash/'+hash.replace(/\//g, "_"));
     axios.get(url,{
        method:'GET',
        crossdomain:true,
        headers:headers,
    }).then(successCallback).catch(catchCallback);
}

const playNowApiCall = (formdata,successCallback,catchCallBack)=>{
    const headers = buildHeaders();
    const url = generateUrlApiV2('/competition/'+formdata.idCompetition+'/optin');
     axios.post(url,formdata,{
        method:'POST',
        crossdomain:true,
        headers:headers,
    }).then(successCallback).catch(catchCallBack);
}


const getLanguageApiCall = (language,successCallback,catchCallBack)=>{
    const headers = buildHeaders();
    const url = generateUrlApiV2('/locale/getLocaleData/'+language);
    axios.get(url,{
        method:'GET',
        crossdomain:true,
        headers:headers,
    }).then(successCallback).catch(catchCallBack);
}

const fetchOfferCall  = (id,successCallback,catchCallBack)=>{
    const headers = buildHeaders();
    const url = generateUrlApiV2('/competition/getoffer/'+id);
    axios.get(url,{
        method:'GET',
        crossdomain:true,
        headers:headers,
    }).then(successCallback).catch(catchCallBack);
}

const voteCall = (formdata,successCallback,catchCallBack)=>{
    const headers = buildHeaders();
    // const url = generateUrl('/vote');
    const url = generateUrlApiV2('/competition/vote');
     axios.post(url,formdata,{
        method:'POST',
        crossdomain:true,
        headers:headers,
    }).then(successCallback).catch(catchCallBack);
}

const updateInventoryAPICall = (id,param,successCallback,catchCallBack)=>{
    const header = buildHeaders();
    const url = generateUrlApiV2('/competition/updateOfferInventory/'+id);
    axios.put(url,param,{
        method:"PUT",
        crossdomain:true,
        headers:header
    }).then(successCallback).catch(catchCallBack);
}


const trackSessionApiCall = (idCompetition, parameters, successCallback, catchCallback) => {

    const headers = buildHeaders();
    const url = generateUrlApiV2(`/event/${idCompetition}`);
  
    axios.post(url, parameters, {
      method: 'POST',
      crossdomain: true,
      headers: headers
    })
      .then(successCallback)
      .catch(catchCallback);
  }
  
  const trackVoteApiCall = (idCompetition, parameters, successCallback, catchCallback) => {
  
    const headers = buildHeaders();
    const url = generateUrlApiV2(`/event/${idCompetition}`);
  
    axios.post(url, parameters, {
      method: 'POST',
      crossdomain: true,
      headers: headers
    })
      .then(successCallback)
      .catch(catchCallback);
  }
  

  const updateOptinToVoteAPICall = (idCompetition, parameters, successCallback, catchCallback) => {
  
    const headers = buildHeaders();
    const url = generateUrlApiV2(`/competition/${idCompetition}/updateOptinToVote`);
  
    axios.put(url, parameters, {
      method: 'PUT',
      crossdomain: true,
      headers: headers
    })
      .then(successCallback)
      .catch(catchCallback);
  }

  

export {
    getConfigData,
    getLanguageApiCall,
    playNowApiCall,
    fetchOfferCall,
    voteCall,
    updateInventoryAPICall,
    trackSessionApiCall,
    trackVoteApiCall,
    updateOptinToVoteAPICall
}
