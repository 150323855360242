import {getLanguageApiCall } from "../util/api_calls/RandomizerApi";


const loadLocale = (locale, dispatch) => {
    getLanguageApiCall(
    locale,
    payload => {
      dispatch(LanguageFinish(payload));
    },
    error => {
      dispatch(LanguageError(error));
    }
  );

  return {
    type: "LOCALE_START",
    payload: {}
  };
};
const LanguageFinish = payload => {
  return {
    type: "LOCALE_FINISH",
    payload: {
      ...payload
    }
  };
};

const LanguageError = error => {
  let payload = {};
  if (error.constructor.name === "Error") {
    // In case 500 is received I don't want to show server's raw error.
    payload = {
      errorMessages: ["There was an error, please contact support."]
    };
  } else {
    payload = { ...error };
  }
  return {
    type: "LANGUAGE_ERROR",
    payload: payload
  };
};

export {loadLocale};