import Config from "./RandomizerReducer";
import  LocaleReducer from "./LocaleReducer"
import TrackingReducer from './TrackingReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    data:Config,
    tracking: TrackingReducer,
    locale:LocaleReducer
});

console.log('rootReducer',rootReducer);

export default rootReducer;
