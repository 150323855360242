import React, { Component } from "react";
import { delayTime } from "../../util/config/Constants";
import { connect } from "react-redux";
import Cookies from 'universal-cookie';
import Fingerprint from 'fingerprintjs'
import { getOffer } from "../../actions/RandomizerAction";
import "./Offer.css";
import Footer from "../footer/Footer";
import { trackVote } from "../../actions/TrackingActions";
import { getUrlWord, showEmbedFooter } from '../../util/helpers/Helpers';

export class Offer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      delayTime: delayTime,
      showBtn: false,
      hash:''
    };
  }


  componentDidMount(prevProps, prevState, snapshot) {
    // let hash = this.props.location.hash;
    // hash = hash ? hash.replace(/(^#)/g, "") : "";
    let hash = getUrlWord(this.props.match.url, this.props.location.hash);

    this.setState({ hash });

    // if(!this.props.config.id){
    //   if (hash.includes("/")) {
    //     this.props.history.push(hash)
    //   } else if (hash.includes("__CURL_")) {
    //     this.props.history.push('');
    //   } else {
    //     // [hash removal] remove this after hash migration
    //     this.props.history.push('/#'+hash)
    //   }
    // }
  }

  checkAndRouteUrl() {
    let hash = getUrlWord(this.props.match.url, this.props.location.hash);
    console.log('hashssss', hash);
    if (hash.includes("/")) {
      this.props.history.push(hash)
    } else if (hash.includes("__CURL_")) {
      this.props.history.push('');
    } else {
      // [hash removal] remove this after hash migration
      this.props.history.push('/#' + hash)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if ((this.props !== prevProps) || (this.state !== prevState)) {
    //   // code
    //   console.log('changedddd props', (this.props !== prevProps));
    //   console.log('changedddd state', (this.state !== prevState));
    // }
    // console.log('curStateeee', this.state);
    // console.log('prevStateeee', prevState);

    if ((this.props !== prevProps) && prevProps && prevProps.config && prevProps.config.id) {
      const idComp = prevProps.config.id;
      const allowMultiUserEntry = localStorage.getItem(`${idComp}-allowMultiUserEntry`);
      console.log('allowww', allowMultiUserEntry);
      if (allowMultiUserEntry && allowMultiUserEntry == 'true') {
        this.checkAndRouteUrl();
      } else {
        const idOptin = localStorage.getItem(`${idComp}-idOptin`);
        const idVote = localStorage.getItem(`${idComp}-idVote`);
        const offer = JSON.parse(localStorage.getItem(`${idComp}-offer`));

        if (idOptin && offer && offer.id && idVote) {
          this.setState({ ...this.state, offer }, () => {
            console.log('updated', this.state);
          });
          let hash = getUrlWord(this.props.match.url, this.props.location.hash);
          if (hash.includes("/")) {
            this.props.history.push(hash + "/claim")
          } else if (hash.includes("__CURL_")) {
            this.props.history.push('claim');
          } else {
            // [hash removal] remove this after hash migration
            this.props.history.push('/claim/#' + hash)
          }
        // } else {
          // this.checkAndRouteUrl();
          // this.props.history.push('/#' + prevProps.config.hash);
        }
      }
    }
  }


  handleSubmit = () => {
    const cookies = new Cookies();
    // const idOptin = cookies.get('idOptin');
    // const idVote = cookies.get('idVote');
    const idComp = this.props.config.id;
    const idOptin =  localStorage.getItem(`${idComp}-idOptin`);
    const idVote =  localStorage.getItem(`${idComp}-idVote`);
    if (this.props.config && idVote && idOptin && this.props.tracking && this.props.tracking.flakeId) {
      const fingerprint = new Fingerprint().get();
      const { flakeId } = this.props.tracking;
      let params = { type: "vote", idVote: idVote, idOptin: idOptin, sessionFlakeId: flakeId, fingerprint: fingerprint }
      this.props.dispatch(trackVote(this.props.config.id, params, this.props.history, this.state.hash, this.props.dispatch));
    }
    const hasEmbedFooter = showEmbedFooter();

    if (this.state.hash.includes("/")) {
      if(hasEmbedFooter){
        this.props.history.push(this.state.hash + "/claim?embed=true");
      }else{
        this.props.history.push(this.state.hash + "/claim");
      }
    }
    else if (this.state.hash.includes("__CURL_")) {
      if (hasEmbedFooter) {
        this.props.history.push("claim?embed=true");
      } else {
        this.props.history.push("claim");
      }
    } else {
      // [hash removal] remove this after hash migration
      if(hasEmbedFooter){
        this.props.history.push("/claim/?embed=true#"+this.state.hash);
      }else{
        this.props.history.push("/claim/#"+this.state.hash);
      }
    }

  };

  render() {
    let { config } = this.props;
    let voteConts = "";
    if (config && Object.keys(config).length > 0) {

      setTimeout(() => {
        this.setState({ showBtn: true });
      }, this.state.delayTime);

      const question = config.questions.find(
        val => val.type === "seven_seconds"
      );

      voteConts = (
        <React.Fragment>
          <div className={"title"}>
            <h1
              className={""}
              style={{ color: config ? config.primaryColor : "" }}
            >
              {question.description}
            </h1>
          </div>
          <div className={"gif text-center"}>
            {question.questionImage.file ? (
              <img
                src={question.questionImage.file}
                className="pl-logo img-fluid center-block"
              />
            ) : null}
          </div>
          <div className="m-auto w-100">
            <div
              className="primary submit-optin cursor_pointer mb-5"
              onClick={this.handleSubmit}
              style={{
                backgroundColor: config ? config.buttonColor : "",
                color: config ? config.secondaryColor : "",
                display: this.state.showBtn ? "block" : "none"
              }}
            >
              {question.questionText}
            </div>
          </div>
          <Footer />
        </React.Fragment>
      );
    }
  
    return <div className={"offer"}>{voteConts}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
 
 console.log(state);
  return {
    ...ownProps,
    config: state.default.data.config,
    locale: state.default.locale,
    offer:state.default.data.offer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
