import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {Provider} from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import {Route,Router} from 'react-router';
import {routerMiddleware, routerReducer} from 'react-router-redux';
import logger from 'redux-logger';

import App from './views/App';
import Home from "./views/home/Home";
import Offer from "./views/offer/Offer";
import Claim from "./views/claim/Claim";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import './util/helpers/i18n'
import * as reducers from "./reducers";
import registerServiceWorker from './registerServiceWorker';
import Landing from './views/landing/Landing';



const history = createHistory();
const middleware = routerMiddleware(history)
const store = createStore(
    combineReducers({
        ...reducers,
        router: routerReducer
    }),
    applyMiddleware(middleware, logger)
);

const restrictedNames = ['/offer', '/claim'];

if ((window && window.location && window.location.hash)
  || window.location.pathname === "/"
  || restrictedNames.includes(window.location.pathname)) {
    console.log('has hash');
  // [hash removal] remove this after hash migration
  ReactDOM.render(

    <Provider store={store}>
      <Router history={history}>
        <div>
          <Route path="*" component={App} />
          {/* <Route exact path="/" component={Landing} /> */}
          <Route exact path="/" component={Home} />
          <Route path="/offer" component={Offer} />
          <Route path="/claim" component={Claim} />
        </div>
      </Router>
    </Provider>,

    document.getElementById("root")
  );
} else {
  ReactDOM.render(

    <Provider store={store}>
      <Router history={history}>
        <div>
          <Route path="*" component={App} />
          <Route exact path="/:brand/:act" component={Home} />
          <Route exact path="/:brand/:act/offer" component={Offer} />
          <Route exact path="/:brand/:act/claim" component={Claim} />
        </div>
      </Router>
    </Provider>,

    document.getElementById("root")
  );
}

 registerServiceWorker();