import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lang: "en",
  fallbackLng: "en",
  debug: true,
  ns: ["common"],
  defaultNS: "common",

  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources: {
    en: {
      common: {
      }
    }
  }
});

export default i18n;
