import React, { Component } from "react";
import { connect } from "react-redux";
import Fingerprint from 'fingerprintjs'
import FadeLoader from "react-spinners/FadeLoader";
import moment from 'moment';


import {
  FormControl,
  HelpBlock,
  Checkbox,
  FormGroup,
  Radio,
  DropdownButton,
  MenuItem
} from "react-bootstrap";
import "./Landing.css";
import { submitPlay } from "../../actions/RandomizerAction";
import { Trans, withTranslation } from "react-i18next";
import { getOffer,getConfig,updateInventory ,updateVote,updateOptinToVote} from "../../actions/RandomizerAction";
import {trackSession,trackVote} from "../../actions/TrackingActions";
import { OFFER_TYPE, INVENTORY_ACTION } from "../../util/config/app-config";
import { getUrlWord, setCustomInputStyling } from "../../util/helpers/Helpers";
import Footer from "../footer/Footer";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export class Landing extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeOptinOther = this.handleChangeOptinOther.bind(this);
    this.handleSelectOptinOtherOption = this.handleSelectOptinOtherOption.bind(
      this
    );
    this.handleSelectOptinOtherOptionSingle = this.handleSelectOptinOtherOptionSingle.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAcceptTnc = this.handleAcceptTnc.bind(this);
    this.state = {
      loader:false,
      charLengthCustom: 'Characters Left: 2000/2000',
    };

  }


  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.config && this.props.config.reduceOfferInventoryAction == INVENTORY_ACTION.GIF_LOAD) {

      if (this.props.config.id && !prevProps.config.id) {
        this.props.dispatch(trackSession(this.props.config.id, this.props.dispatch));
      }

      if (this.props.config && this.props.idVote && this.props.tracking.flakeId && !prevProps.idVote) {

        const fingerprint = new Fingerprint().get()
        const idVote = this.props.idVote;
        const { flakeId } = this.props.tracking;
        let params = { type: "vote", idVote: idVote, sessionFlakeId: flakeId, fingerprint: fingerprint }
        this.props.dispatch(trackVote(this.props.config.id, params, this.props.history, this.state.hash, this.props.dispatch));

      }

      // if (this.props.config && this.props.idVote && this.props.idOptin && !prevProps.idOptin) {
      //   const idVote = this.props.idVote;
      //   this.props.dispatch(updateOptinToVote(this.props.config.id, { idOptin: this.props.idOptin, idVote: idVote }, this.props.dispatch));
      // }


      if (this.props.offer && this.props.offer.id && !prevProps.offer.id) {
        if (this.props.offer.type === OFFER_TYPE.OFFER) {
          const id = this.props.config.id;
          let payload = {
            id: this.props.offer.id,
            inventory: this.props.offer.offerInventory - 1
          };
          this.props.dispatch(updateInventory(id, payload, this.props.dispatch));
        }

        const { id, idCompetition } = this.props.offer;
        let params = {
          selectionIds: [{ questionId: id, selectionId: 0 }],
          idCompetition: idCompetition
        };
        this.props.dispatch(updateVote(this.props, params, this.props.dispatch, false));
      }
    }

    if ((this.props !== prevProps) && prevProps && prevProps.config && prevProps.config.id) {
      const idComp = prevProps.config.id;
      const allowMultiUserEntry = localStorage.getItem(`${idComp}-allowMultiUserEntry`);
      console.log('allowww', allowMultiUserEntry);
      if (!allowMultiUserEntry || allowMultiUserEntry == 'false') {
        const idOptin = localStorage.getItem(`${idComp}-idOptin`);
        const idVote = localStorage.getItem(`${idComp}-idVote`);
        const offer = JSON.parse(localStorage.getItem(`${idComp}-offer`));

        if (idOptin && offer && offer.id) {
          this.setState({ ...this.state, offer }, () => {
            console.log('updated', this.state);
          });
          let hash = getUrlWord(this.props.match.url, this.props.location.hash);
          if (hash.includes("/")) {
            this.props.history.push(hash + "/claim")
          } else if (hash.includes("__CURL_")) {
            this.props.history.push('claim');
          } else {
            // [hash removal] remove this after hash migration
            this.props.history.push('/claim/#' + hash)
          }
        }
      }
    }

  }

  componentDidMount() {
    // let hash = this.props.location.hash;
    // hash = hash ? hash.replace(/(^#)/g, "") : "";
    let hash = getUrlWord(this.props.match.url, this.props.location.hash);

    this.setState({ hash });
    if (hash) {
      this.props.dispatch(getConfig(hash, this.props.dispatch));
      this.setState({ hash });
      this.setState({ ...this.state });
    }
  }

  handleSubmit = () => {

  };

  render() {
    let { config } = this.props;
    let voteCont = "";
    if (Object.keys(config).length > 0) {

      voteCont = (
        <div
          className="tx d-flex flex-column"
          id="app-page-3"
          style={{ color: config.primaryColor }}
        >
            {setCustomInputStyling(config.primaryColor ? config.primaryColor : '#fff')}
            {config.logoBg || (config.sponsorsBg && config.sponsorsTop) ? (
            <div className={'intro'}>
              <div>
                {config.logoBg ? (
                  <div className={'logos-cont'}>
                    <img src={config.logoBg.file} className={'logo-image'}/>
                  </div>
                ) : null}
                {config.sponsorsBg && config.sponsorsTop ? (config.sponsorsLink && config.sponsorsLink != '') ? (
                  <a href={config.sponsorsLink} target="_blank">
                    <div className={'logos-cont'}>
                      <img src={config.sponsorsBg.file} className={'logo-image sponsors'}/>
                    </div>
                  </a>
                ) : (
                  <div className={'logos-cont'}>
                    <img src={config.sponsorsBg.file} className={'logo-image sponsors'}/>
                  </div>
                ) : null}
              </div>
            </div>
            ) : null}
          <div
            style={{
              color: config ? config.primaryColor : ""
            }}
            className="vote-cont"
          >
            <h1
              style={{ color: config ? config.primaryColor : "" }}
              dangerouslySetInnerHTML={{ __html: config.detail }}
              className={"mini-site-title"}
            />
            <p
              style={{ color: config ? config.primaryColor : "" }}
              dangerouslySetInnerHTML={{
                __html: config.description
              }}
              className={"site-description mini-site-description"}
            />
            <h1
              style={{ color: config ? config.primaryColor : "" }}
              dangerouslySetInnerHTML={{__html: config.lastPageCopy}}
              className={"site-description"}
            />
          </div>

          
        </div>
      );
    }
    return (<React.Fragment>
       {this.state.loader ? (
          <div className={"loaderCss"}>
            <div className={"loader"}>
              <FadeLoader
                size={150}
                color={"#ffffff"}
                loading={this.state.loader}
              />
            </div>
          </div>
        ) : null}
        <div className={"parent-vote-cont"}>{voteCont}</div>
        <Footer />
    </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log('state--->',state);
  return {
    ...ownProps,
    config: state.default.data.config,
    locale: state.default.locale,
    offer: state.default.data.offer,
    tracking: state.default.tracking
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Landing));
