import React from 'react';

export const decodeOtherLanguage = function (strToDecode) {
    const result = decodeURIComponent(escape(window.atob(strToDecode)));
    return result === 'null'?'':result;
};

export const setCustomInputStyling = function (primaryColor) {
    var primaryColorUrlSafe = encodeURIComponent(primaryColor);
    return (
        <style dangerouslySetInnerHTML={{
            __html: `
        .tx .custom-control-label::before {
            border: 2px solid `+ primaryColor + `;
        }
        .custom-radio input:checked~.custom-control-label::after {
          background-image: url('data:image/svg+xml;utf8,<svg fill="`+ primaryColorUrlSafe + `" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve"><circle class="st0" cx="4" cy="4" r="3"/></svg>');
        }
        .custom-checkbox input:checked~.custom-control-label::after {
          background-image: url('data:image/svg+xml;utf8,<svg fill="`+ primaryColorUrlSafe + `" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve"><path class="st0" d="M6.6,0.8L3,4.4L1.4,2.8L0,4.3l3,3l5-5.1L6.6,0.8z"/></svg>');
        }
      `}}></style>
    );
};

export const showEmbedFooter = function() {
  const url = new URL(window.location);
  let showEmbedFooter = false;
  if (url) {
    const params = new URLSearchParams(url.search);
    if (params) {
      showEmbedFooter = (params.get("embed") && params.get("embed") === 'true')?true:false;
      console.log('showFooter ', showEmbedFooter);
    }
  }
  return showEmbedFooter;
};

export const getUrlWord = function (urlPath, hash = null) {
  // [hash removal] remove this after hash migration
  if (hash && hash != '') {
    return hash.replace(/(^#)/g, "");
  }
  let urlArray = urlPath.split('/');
  if (urlArray[1] && urlArray[1] != '' && urlArray[2] && urlArray[2] != '') {
    return `/${urlArray[1]}/${urlArray[2]}`;
  }
  console.log('window', '__CURL_' + window.location.hostname.replace(/\./g, "_"));
  return '__CURL_' + window.location.hostname.replace(/\./g, "_");
};